import React from 'react';
import { observer } from 'mobx-react';

import styles from './create-account.form.module.scss';

import { redirect, redirectToLogin } from '~/util/redirect';
import { EmailField } from '~/util/formz/Components/fields/EmailField';
import { PasswordField } from '~/util/formz/Components/fields/PasswordField';
import { Field } from '~/util/formz/Components/fields/Field';
import { CheckboxField } from '~/util/formz/Components/fields/CheckboxField';
import { useGlobalContext } from '~/global/Contexts/Global.context';
import { TextLink } from '~/components/Buttons/Components/TextLink';

export const CreateAccountForm = observer((props) => {
	const {
		globalDynamicModel: {
			loginPageLink,
			isLoggedIn = false,
		} = {},
		HREF,
		featureTogglesModel,
	} = useGlobalContext();
	const { form } = props.store;
	const accountUrl = featureTogglesModel.isOn('ACCOUNT_OVERVIEW') ? HREF.account.overview : HREF.account.profile;

	const signInLinkClicked = (e) => {
		e.preventDefault();

		if (props.store.isInModal) {
			props.modalStore.openModal('signIn', {
				callback: props.store.callback,
				formOverrides: props.store.defaults,
			});
		} else if (loginPageLink) {
			redirect(loginPageLink);
		} else if (isLoggedIn) {
			redirect(accountUrl);
		} else {
			redirectToLogin();
		}
	};

	const {
		reactProps: {
			className = '',
		} = {},
	} = form;

	const formProps = {
		...form.reactProps,
		className: `${className} ${styles.createAccountForm}`
	};

	return (
		<form {...formProps}>
			<legend className="required-legend-no-star tw-text-gray">All information is required</legend>

			{props.store.hasRequestError && (
				<div className="validation">
					There was a problem with your request. Please validate and try again.
				</div>
			)}

			<EmailField
				field={form.fields.username}
				afterLabel={(
					<div className="label-descriptor">
						You will use this to sign into your account.
					</div>
				)}
			/>

			<PasswordField
				field={form.fields.password}
				showPasswordField={form.fields.showPassword}
				afterLabel={(
					<div className="label-descriptor">
						Your password must be at least 6 characters.
					</div>
				)}
			/>

			<Field field={form.fields.zipCode} />

			<CheckboxField field={form.fields.subscribe} />

			<div className={styles.termsOfUse}>
				By creating an account, you agree to Room & Board’s{' '}
				<TextLink
					href="/terms-of-use/"
					target="_blank"
					data-ignore-blur-validation
				>
					Terms of Use.
				</TextLink>
			</div>

			<input
				id="submitFormButton"
				data-qa="submit-form-button"
				type="submit"
				className="Button"
				key="create-account-submit"
				value="Submit"
			/>

			<div className={styles.signInLink}>
				Already have an account?{' '}
				<TextLink
					href="/account/sign-in/"
					onClick={signInLinkClicked}
					data-qa="sign-in-link"
					data-ignore-blur-validation
				>
					Sign In
				</TextLink>
			</div>
		</form>
	);
});
