import React, { ComponentProps } from 'react';

import { TextLinkColor } from '../Types/constants';
import { buildTextLinkClassNames } from '../Utils/Button.utils';

import { ButtonBase } from './ButtonBase';

export interface IProps extends Omit<ComponentProps<typeof ButtonBase>, 'color'> {
	color?: TextLinkColor;
	hasNoUnderline?: boolean;
}

export const TextButton = (props: IProps) => {
	const { color, hasNoUnderline = false, ...buttonProps } = props;
	const className = buildTextLinkClassNames(color, hasNoUnderline);

	return (
		<ButtonBase
			{...buttonProps}
			className={className}
		>
			{props.children}
		</ButtonBase>
	);
};
