import React from 'react';
import Script from 'next/script';

import { siteCatalystAccount } from '~/global/global.constants';

export const PublicGlobals = () => {
	// TODO: Figure out if this is needed
	return (
		<>
			<Script
				strategy="afterInteractive"
			>
				{`
					window.RNB = {
						Monetate: {
							appData: {
								Features: []
							}
						}
					};

					window.adobeReportSuites = '${siteCatalystAccount}';
					window.dataLayer = window.dataLayer || {};
					window.monetateT = new Date().getTime();
				`}
			</Script>
		</>
	);
};
