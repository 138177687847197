import { action, observable, makeObservable } from 'mobx';
import dynamic from 'next/dynamic';
import React from 'react';
import classNames from 'classnames';

import { isFunction } from '~/util/isFunction';
import { isEngage } from '~/global/global.constants';
import { ViewStateStore } from '~/util/viewState/Stores/ViewState.store';
import { ViewState } from '~/util/viewState/Components/ViewState';
import { OneTrustStore } from '~/account/sign-in/Stores/OneTrust.store';
import { SignInStore } from '~/account/sign-in/Stores/SignIn.store';
import { CreateAccountPage } from '~/account/create/Components/CreateAccountPage';
import { CreateAccountStoreFactory } from '~/account/create/Stores/CreateAccount.store';
import { createAccountHandler } from '~/engage/toolbar/create-account/createAccountHandler';
import { LoadingSpinner } from '~/util/Components/LoadingSpinner';

import styles from '#/account/sign-in/sign-in.module.scss';

const SignInPage = dynamic(
	() => import('~/account/sign-in/Components/SignInPage').then(module => module.SignInPage),
	{ loading: () => <LoadingSpinner isLoading /> },
);

class SignInModalStore {
	HREF;

	magicModal;

	globalDynamicStore;

	viewState = new ViewStateStore(this, {
		signIn: {
			component: SignInPage,
			onEnter: (viewState, store, parameters) => {
				const oneTrustStore = new OneTrustStore({});

				const signInStore = SignInStore(
					parameters.callback,
					{
						globalDynamicStore: this.globalDynamicStore,
						globalDynamicModel: this.globalDynamicStore.model,
						HREF: this.HREF,
					},
					{ isInModal: true },
					{},
					oneTrustStore,
				);

				return {
					store: signInStore,
					modalStore: store,
				};
			}
		},
		createAccount: {
			component: CreateAccountPage,
			onEnter: (viewState, store, parameters) => {
				const createAccountStore = CreateAccountStoreFactory.create(
					parameters.callback,
					{
						...parameters.formOverrides,
						isInModal: true,
					},
					this.globalDynamicStore,
					this.HREF,
				);

				return {
					store: createAccountStore,
					modalStore: store,
				};
			}
		}
	});

	constructor() {
		makeObservable(this, {
			magicModal: observable,
			globalDynamicStore: observable,
			openModal: action.bound
		});
	}

	openModal(viewKey, options = {}) {
		if (isEngage) {
			createAccountHandler(this.magicModal, this.globalDynamicStore, {
				modalOptions: options?.modalOptions,
				onCreateAccountSuccess: () => {
					this.magicModal.closeModal();
					options.callback?.();
				},
				onCreateAccountSuccessView: null,
			});
		} else {
			this.viewState.goTo(viewKey, options);
			this.magicModal.openModal(this.getModalOptions(options.modalOptions));
		}
	}

	getModalOptions(overrides = {}) {
		const currentViewKey = this.viewState.currentView.key;
		const defaultViewOptions = {};
		const currentViewDefaultOptions = defaultViewOptions?.[currentViewKey] || {};
		const {
			onCloseModal: onCloseModalDefault = null,
			...omittedDefaultOptions
		} = currentViewDefaultOptions;
		const {
			onCloseModal: onCloseModalOverride = null,
			...omittedOverrides
		} = overrides;

		return {
			maxWidth: '900px',
			fullBleed: true,
			accessibleTitle: 'Sign in modal',
			title: '',
			initialScrollTop: 0,
			content: {
				children: (
					<div className={classNames(styles['login-container'], 'login-container')}>
						<ViewState viewState={this.viewState} />
					</div>
				),
			},
			...omittedDefaultOptions,
			...omittedOverrides,
			trLinkEventCompName: overrides.trLinkEventCompName || null,
			trLinkEventCompType: overrides.trLinkEventCompType || 'modal',
			onCloseModal() {
				if (isFunction(onCloseModalOverride)) {
					onCloseModalOverride();
				} else if (isFunction(onCloseModalDefault)) {
					onCloseModalDefault();
				}
			}
		};
	}

	autoruns = [];
}

const SignInModalStoreFactory = {
	create(magicModal, globalDynamicStore, HREF) {
		const store = new SignInModalStore();

		store.magicModal = magicModal;
		store.globalDynamicStore = globalDynamicStore;
		store.HREF = HREF;

		return store;
	},
	destroy(store) {
		store.autoruns?.forEach?.(ar => ar());
	}
};

export { SignInModalStoreFactory };
