import React, { PropsWithChildren } from 'react';
import NextLink, { LinkProps as NextLinkProps } from 'next/link';
import classNames from 'classnames';

import { TextLinkColor } from '../Types/constants';
import { buildTextLinkClassNames } from '../Utils/Button.utils';

export interface IProps extends NextLinkProps {
	color?: TextLinkColor;
	hasNoUnderline?: boolean;
	className?: string;
}

export const NextJsTextLink = (props: PropsWithChildren<IProps>) => {
	const {
		color,
		hasNoUnderline,
		className,
		...nextProps
	} = props;
	const cn = classNames(className, buildTextLinkClassNames(color, hasNoUnderline));

	return (
		<NextLink
			{...nextProps}
			className={cn}
		>
			{props.children}
		</NextLink>
	);
};
